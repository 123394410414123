import React, { useState, useEffect } from 'react'
import { TimelineMax } from 'gsap';
import styles from './ItemProduct.module.scss';

import ssl from '../../assets/images/ssl.jpg';
import hosting from '../../assets/images/hosting.jpg'
import care from '../../assets/images/care.jpg'


const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {

    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])

  return [setRef, visible]
}


const ItemProduct = () => {

  const [setRef, visible] = useOnScreen({threshold: 0.5})

  const [tl] = useState(new TimelineMax())
  let box1 = null;
  let box2 = null;
  let box3 = null;


  useEffect(() => {
    if(visible) {
       tl
        .to(box1, 0.6, {y: 0, scale: 1})
        .to(box2, 0.6, {y: 0, scale: 1})
        .to(box3, 0.6, {y: 0, scale: 1})
    }
    // eslint-disable-next-line
  }, [visible]);


  const [tlTwo] = useState(new TimelineMax());
  let showBox1 = null;
  let showBox2 = null;
  let showBox3 = null;

  const onMouseEnter1 = () => {
    tlTwo
    .to(showBox1, 0.5, {scale: 1})
  }
  const onMouseLeave1 = () => {
    tlTwo
      .to(showBox1, 0.5, {scale: 0})
  }

  const onMouseEnter2 = () => {
    tlTwo
    .to(showBox2, 0.5, {scale: 1})
  }
  const onMouseLeave2 = () => {
    tlTwo
      .to(showBox2, 0.5, {scale: 0})
  }

  const onMouseEnter3 = () => {
    tlTwo
    .to(showBox3, 0.5, {scale: 1})
  }
  const onMouseLeave3 = () => {
    tlTwo
      .to(showBox3, 0.5, {scale: 0})
  }

  return (
    <div className={styles.wrapper} ref={setRef}>

        <div className={styles.divProductHosting} ref={divH => box1 = divH}>
          <div className={styles.img} onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}>
            <img className={styles.imgProduct} src={hosting} alt="hosting BluSerwer"/>
            <div className={styles.hiddenBox} ref={div => showBox1 = div}>
              <p className={styles.text}>Nasz hosting wspiera popularne systemy CMS, takie jak WordPress, PrestaShop, czy CMS Made Simple. Dla bardziej wymagających klientów oferujemy serwery VPS oparte o systemy Windows lub Linux.</p>
              <a href="https://bluserwer.pl/hosting-wspoldzielony/" target="_blank" rel="noopener noreferrer" className={styles.link}>Wypróbuj</a>
            </div>
          </div>
          <h3 className={styles.underTitle}>Bezpieczny hosting</h3>
        </div>

        <div className={styles.divProductVPS} ref={divV => box2 = divV}>
          <div className={styles.img} onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}>
            <img className={styles.imgProduct} src={ssl} alt="serwery VPS"/>
            <div className={styles.hiddenBox} ref={div => showBox2 = div}>
              <p className={styles.text}>SSL to podstawa bezpieczeństwa szyfrowania Twoich danych. Dodatkowo wzmacnia on pozycję Twojej strony internetowej w przeglądarce Google. Nie ryzykuj, zabezpiecz stronę już dziś.</p>
              <a href="https://bluserwer.pl/vps-linux/" target="_blank" rel="noopener noreferrer" className={styles.link}>Wypróbuj</a>
            </div>
          </div>
          <h3 className={styles.underTitle}>Certyfikaty SSL</h3>
        </div>

        <div className={styles.divProductDomain} ref={divD => box3 = divD}>
          <div className={styles.img} onMouseEnter={onMouseEnter3} onMouseLeave={onMouseLeave3}>
            <img className={styles.imgProduct} src={care} alt="domeny"/>
            <div className={styles.hiddenBox} ref={div => showBox3 = div}>
              <p className={styles.text}>Wiemy, że sprzęt na którym pracujesz i dane, które gromadzisz są kluczowe dla funkcjonowania Twojej firmy. Pracujemy nad tym, by kluczowe dla Ciebie zasoby były niezagrożone, a praca przebiegała zgodnie z planem.</p>
              <a href="https://bluserwer.pl/domeny/" target="_blank" rel="noopener noreferrer" className={styles.link}>Wypróbuj</a>
            </div>
          </div>
          <h3 className={styles.underTitle}>Opieka informatyczna</h3>
        </div>

    </div>
  )


  // return (
  //   <div className={styles.wrapper} ref={setRef}>

  //     <div className={styles.img} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={div => box = div}>
  //       <img className={styles.imgProduct} src={hosting} alt="hosting BluSerwer"/>
  //       <div className={styles.hiddenBox} ref={div => showBox = div}>
  //         <p className={styles.text}>Prowadzisz małą, mikro lub dużą firmę? Prowadzisz profesjonalnego bloga? Jeśli tak to hosting na BluSerwer.pl będzie dla Ciebie idealny!</p>
  //         <a href="https://bluserwer.pl" target="_blank" rel="noopener noreferrer" className={styles.link}>Wypróbuj</a>
  //       </div>
  //     </div>
  //     <h3 className={styles.underTitle}>Hosting</h3>

  //   </div>
  // )

}

export default ItemProduct
