import React, { useState } from 'react'
import styles from './Footer.module.scss';

const Footer = () => {

  const [Smile, setSmile] = useState(false);
  const onMouseEnter = () => setSmile(!Smile);
  const onMouseLeave = () => setSmile(!Smile);

  return (
    <div className={styles.wrapper}>
      <div className={styles.widthSize}>

        <div className={styles.contact}>
          <h2 className={styles.write}>
            Napisz <span className={styles.titleSpan}>do nas {Smile ? <i className="far fa-laugh-beam"/> : <i className="far fa-laugh-wink"/>}</span>
          </h2>
          <a className={styles.email} href="mailto:zgloszenia@bluserwer.pl" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>zgloszenia@bluserwer.pl</a>
        </div>
        <div className={styles.address}>
          <p>Kijowska 44</p>
          <p>Bydgoszcz</p>
          <p><a className={styles.phone} href="tel:52 522 22 42">(52) 522 22 42</a></p>
        </div>
        <div className={styles.copyright}>
          <a className={styles.blusoft} href="https://bluserwer.pl" target="_blank" rel="noopener noreferrer"><p><i className="far fa-copyright"/> BluSoft.pl 2019</p></a>
          <p className={styles.frond}><i className="fas fa-code"/> J.J</p>
        </div>

      </div>
    </div>
  )
}

export default Footer
